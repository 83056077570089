<template>
  <div>
    <div class="content common-department">
      <br>
      <span class="text-common" style="font-weight: bold">Check the box below if you would like to remove the countermat from the application </span>
      <br>
      <br>
      <div>
        <br>
        <div class="d-flex justify-content-start">
          <label class="check-box" :class="{ dis_checkbox: isEditable == false }">
            <input v-model="removeBtnOption" type="checkbox" /> Hide simulation button
            <span class="checkmark" />
          </label>
        </div>
      </div>
      <br>
      <div>
        <span>Do ensure that the images for products in a higher rank looks better then images for products in a lower rank</span>
      </div>
      <br>
      
      <div class="fluid">
        <b-row class="mb-1">
          <b-col sm="5" lg="3">
            <span class="font-weight-bold">Button name</span>
          </b-col>
          <b-col sm="7">
            <span class="font-weight-bold">Button content</span>
          </b-col>
        </b-row>
        
        <b-row class="mt-1">
          <b-col sm="5" lg="3">
            <div v-if="isEditable">
              <CustomEditor 
                v-if="isEditable"
                v-model="btnName"
                type="textarea"
                :maxlength="26"
                class-name="sm"
                custom-style="word-break: break-word;"
                main-class-name="w-100"
              />
            </div>
            <!-- <div v-if="isEditable" class="main">
              <textarea-autosize
                v-model="btnName"
                :maxlength="26"
                class="sm"
              />
              <span>{{ btnName.length }}/{{ 26 }}</span>
            </div> -->
            <CustomEditor v-else v-model="btnName" custom-style="word-break: break-word;" />
          </b-col>
          <b-col v-if="btnContent===ALL_PACKAGES" sm="4">
            <b-dropdown
              v-if="isEditable"
              :text="btnContent"
              class="sm"
              variant="outline-dark"
              :style="'height: calc(1.5em + 0.75rem + 2px);'"
            >
              <b-dropdown-item @click="btnContent=ALL_PACKAGES">
                {{ ALL_PACKAGES }}
              </b-dropdown-item>
              <b-dropdown-item @click="btnContent=ENTER_PASSWORD">
                {{ ENTER_PASSWORD }}
              </b-dropdown-item>
            </b-dropdown>
            <label v-if="!isEditable"> {{ btnContent }}</label>
          </b-col>
          <b-col 
            v-if="btnContent===ENTER_PASSWORD" 
            sm="7"
            style="display: flex; flex-wrap: wrap"
          >
            <b-dropdown
              v-if="isEditable"
              :text="btnContent"
              class="sm m-top"
              variant="outline-dark"
              style="margin-bottom: 10px; margin-right: 5px;height: calc(1.5em + 0.75rem + 2px);"
            >
              <b-dropdown-item @click="btnContent=ALL_PACKAGES">
                {{ ALL_PACKAGES }}
              </b-dropdown-item>
              <b-dropdown-item @click="btnContent=ENTER_PASSWORD">
                {{ ENTER_PASSWORD }}
              </b-dropdown-item>
            </b-dropdown>
            <div v-if="isEditable" style="margin-bottom: 10px;">
              <b-form-input
                v-model="txtPassword"
                style="width: auto; display: inline-block"
                class="sm"
              />
            </div>
            <label v-if="!isEditable"> {{ txtPassword }}</label>
          </b-col>
        </b-row>
      </div>
      
      <br>
      <br>      
      <table id="table-edit" class="table-sm table custom-table" style="width: 100%" responsive sm borderless>
        <thead>
          <tr bgcolor="#F6F6F6">
            <th width="15%" :class="isEditContent">
              <strong>TYPE</strong>
            </th>
            <th width="25%" :class="isEditContent">
              <strong>PRODUCT NAME</strong>
            </th>
            <th
              width="30%"
              :class="isEditContent"
            >
              <strong>IMAGE WITHOUT WIREFRAME</strong>
              <div class="header-desc">
                .jpeg or .png files only. Max 1MB, Dimension 1641 X 976px
              </div>
            </th>
            <th width="30%" :class="isEditContent">
              <strong>IMAGE WITH WIREFRAME</strong>
              <div class="header-desc">
                .jpeg or .png files only. Max 1MB, Dimension 1641 X 976px
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <!--------------------- Single Vision With Blur -------------------->
          <tr v-for="(product, index) in getSingleVisionCategory.products" :key="product.id">
            <td
              v-if="index === 0"
              :rowspan="getSingleVisionCategory.products.length"
            >
              <p>
                <strong
                  style="color: #006db6"
                >
                  <!-- {{ getSingleVisionCategory.name }} -->
                  <CustomEditor v-model="getSingleVisionCategory.name" />
                </strong>
              </p>
              <p class="type-desc">
                With distance blur, when comparing with Relaxsee product
              </p>
            </td>
            
            <td :class="{ 'border-top': index > 0 }">
              <!-- {{ product.name }} -->
              <CustomEditor v-model="product.name" />
            </td>
            
            <td :class="{ 'border-top': index > 0 }">
              <single-vision-image-without-wireframe :product="product" :index="index" :image-path="IMAGE_API_PATH" />
            </td>
            
            <td
              :class="{ 'border-top': index > 0 }"
            >
              <single-vision-image-with-wireframe :product="product" :index="index" :image-path="IMAGE_API_PATH" />
            </td>
          </tr>
          
          <!--------------------- Single Vision Without Blur -------------------->
          <tr v-for="(product, index) in getSingleVisionCategory.products" :key="product.id">
            <td
              v-if="index === 0"
              :rowspan="getSingleVisionCategory.products.length"
            >
              <p>
                <strong
                  style="color: #006db6"
                >
                  <!-- {{ getSingleVisionCategory.name }} -->
                  <CustomEditor v-model="getSingleVisionCategory.name" />
                </strong>
              </p>
              <p class="type-desc">
                Without distance blur, for comparing with other SV products
              </p>
            </td>
            
            <td v-if="product.isRelaxsee === false" :class="{ 'border-top': index > 0 }">
              <!-- {{ product.name }} -->
              <CustomEditor v-model="product.name" />
            </td>
            
            <td v-if="product.isRelaxsee === false" :class="{ 'border-top': index > 0 }">
              <single-vision-image-without-wireframe-without-blur :product="product" :index="index" :image-path="IMAGE_API_PATH" />
            </td>
            
            <td v-if="product.isRelaxsee === false"
                :class="{ 'border-top': index > 0 }"
            >
              <single-vision-image-with-wireframe-without-blur :product="product" :index="index" :image-path="IMAGE_API_PATH" />
            </td>
          </tr>
          
          <!--------------------- Progressive Lens -------------------->
          <tr
            v-for="(product, index) in getProgressiveLensCategory.products"
            :key="product.id"
          >
            <td
              v-if="index === 0"
              :rowspan="getProgressiveLensCategory.products.length"
            >
              <strong
                style="color: #006db6"
              >
                <!-- {{ getProgressiveLensCategory.name }} -->
                <CustomEditor v-model="getProgressiveLensCategory.name" />
              </strong>
            </td>
            
            <td :class="{ 'border-top': index > 0 }">
              <!-- {{ product.name }} -->
              <CustomEditor v-model="product.name" />
            </td>
            
            <td :class="{ 'border-top': index > 0 }">
              <progressive-lens-image-without-wireframe :product="product" :index="index" :image-path="IMAGE_API_PATH" />
            </td>
            
            <td
              :class="{ 'border-top': index > 0 }"
            >
              <progressive-lens-image-with-wireframe :product="product" :index="index" :image-path="IMAGE_API_PATH" />
            </td>
          </tr>
          
          <!--------------------- Voila -------------------->
          <tr v-for="(product, index) in getVoilaCategory.products" :key="product.id">
            <template v-if="checkCategoryElife=='E_LIFE_PRO'">
              <td
                v-if="index === 0"
                :rowspan="getVoilaCategory.products.length"
              >
                <strong
                  style="color: #006db6"
                >
                  <!-- {{ getVoilaCategory.name }} -->
                  <CustomEditor v-model="getVoilaCategory.name" />
                </strong>
              </td>
              
              <td :class="{ 'border-top': index > 0 }">
                <!-- {{ product.name }} -->
                <CustomEditor v-model="product.name" />
              </td>
              
              <td :class="{ 'border-top': index > 0 }">
                <voila-image-without-wireframe :product="product" :index="index" :image-path="IMAGE_API_PATH" />
              </td>
              
              <td :class="{ 'border-top': index > 0 }">
                <voila-image-with-wireframe :product="product" :index="index" :image-path="IMAGE_API_PATH" />
              </td>
            </template>
          </tr>
          
          <tr v-for="(product, index) in getSeriesCategory.products" :key="product.id">
            <template v-if="checkCategoryElife == 'E_LIFE'">
              <td
                v-if="index === 0"
                :rowspan="getSeriesCategory.products.length"
              >
                <strong
                  style="color: #006db6"
                >
                  <!-- {{ getSeriesCategory.name }} -->
                  <CustomEditor v-model="getSeriesCategory.name" />
                </strong>
              </td>
              
              <td :class="{ 'border-top': index > 0 }">
                <!-- {{ product.name }} -->
                <CustomEditor v-model="product.name" />
              </td>
              
              <td :class="{ 'border-top': index > 0 }">
                <series-image-without-wireframe :product="product" :index="index" :image-path="IMAGE_API_PATH" />
              </td>
              
              <td :class="{ 'border-top': index > 0 }">
                <series-image-with-wireframe :product="product" :index="index" :image-path="IMAGE_API_PATH" />
              </td>
            </template>
          </tr>
          <tr v-for="(product, index) in getConventionalCategory.products" :key="'cnv' + product.id">
            <td
              v-if="index === 0"
              :rowspan="getConventionalCategory.products.length"
            >
              <strong
                style="color: #006db6"
              >
                <!-- {{ getConventionalCategory.name }} -->
                <CustomEditor v-model="getConventionalCategory.name" />
              </strong>
            </td>
            
            <td :class="{ 'border-top': index > 0 }">
              <!-- {{ product.name }} -->
              <CustomEditor v-model="product.name" />
            </td>
            
            <td :class="{ 'border-top': index > 0 }">
              <conventional-image-without-wireframe :product="product" :index="index" :image-path="IMAGE_API_PATH" />
            </td>
            
            <td :class="{ 'border-top': index > 0 }">
              <conventional-image-with-wireframe :product="product" :index="index" :image-path="IMAGE_API_PATH" />
            </td>
          </tr>
        </tbody>
      </table>
      <!--######################### The Modal ERRORR ###########################################################################-->
      <div v-if="isShowError">
        <transition name="modal">
          <div class="modal-mask-confirm">
            <div class="modal-wrapper">
              <div
                class="modal-dialog"
                role="document"
              >
                <div class="modal-content custom-dialog-confirm">
                  <div class="modal-body">
                    <div class="body-text-modal-confirm">
                      <p>
                        {{ getMessage }}<br>
                      </p>
                    </div>
                    <div
                      class="btn-modal-confirm"
                    >
                      <button
                        type="button"
                        class="btn btn-light"
                        style="color:#006DB6; margin-left:20px; width: 127px; height: 40px;"
                        @click="updateShowError(false)"
                      >
                        OK
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!--######################### The Modal ERRORR END ###########################################################################-->
    </div>
  </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
import { fixHeaderTable } from "../../common/CommonUtils"
import Constants from '../../common/Constants'
import { CONTEXT_PATH, LOAD_LARGE_IMAGE_API_PATH } from '../../constants/Paths'
import ConfigurationService from "../../service/ConfigurationService"
import LensSliderService from "../../service/LensSliderService"
import SurveyConfigurationService from "../../service/SurveyConfigurationService"
import ProgressiveLensImageWithWireframe from './component/ProgressiveLensImageWithWireframe.vue'
import ProgressiveLensImageWithoutWireframe from './component/ProgressiveLensImageWithoutWireframe.vue'
import SeriesImageWithWireframe from "./component/SeriesImageWithWireframe"
import SeriesImageWithoutWireframe from "./component/SeriesImageWithoutWireframe"
import SingleVisionImageWithWireframe from './component/SingleVisionImageWithWireframe.vue'
import SingleVisionImageWithWireframeWithoutBlur from './component/SingleVisionImageWithWireframeWithoutBlur.vue'
import SingleVisionImageWithoutWireframe from './component/SingleVisionImageWithoutWireframe.vue'
import SingleVisionImageWithoutWireframeWithoutBlur from './component/SingleVisionImageWithoutWireframeWithoutBlur.vue'
import VoilaImageWithWireframe from './component/VoilaImageWithWireframe.vue'
import VoilaImageWithoutWireframe from './component/VoilaImageWithoutWireframe.vue'

import CustomEditor from "../../components/custom/CustomEditor.vue"
import ConventionalImageWithWireframe from './component/ConventionalImageWithWireframe.vue'
import ConventionalImageWithoutWireframe from './component/ConventionalImageWithoutWireframe.vue'

    export default {
        components: {
            SeriesImageWithoutWireframe,
            SeriesImageWithWireframe,
            SingleVisionImageWithWireframe,
            SingleVisionImageWithoutWireframe,
            SingleVisionImageWithWireframeWithoutBlur,
            SingleVisionImageWithoutWireframeWithoutBlur,
            ProgressiveLensImageWithWireframe,
            ProgressiveLensImageWithoutWireframe,
            VoilaImageWithWireframe,
            VoilaImageWithoutWireframe,
            ConventionalImageWithWireframe,
            ConventionalImageWithoutWireframe,
            CustomEditor
        },
        data() {
            const surveyId = this.$store.state.survey.id
            return {
                surveyId: surveyId <= 0 ? 1 : surveyId,
                IMAGE_API_PATH: CONTEXT_PATH + LOAD_LARGE_IMAGE_API_PATH,
                ALL_PACKAGES: Constants.LENS_SLIDER_ALL_PACKAGES,
                ENTER_PASSWORD: Constants.LENS_SLIDER_ENTER_PASSWORD,
                btnName: 'iPad Revealer',
                btnContent: 'All packages',
                txtPassword: '',
                checkCategoryElife: '',
                removeBtnOption: false,
                disabledVoilaProducts: [],
                disabledSeriesProducts: [],

            }
        },
        computed:{
            ...mapGetters([
                'isEditable',
                'isShowError',
                'getMessage',
                'getSingleVisionCategory',
                'getProgressiveLensCategory',
                'getVoilaCategory',
                'getSeriesCategory',
                'getConventionalCategory',                
            ]),
            isEditContent() {
                return this.isEditable
                    ? "edit-th-content"
                    : "normal-th-content"
            },
        },
        mounted() {
            this.fetch()
            this.loadConfig()
        },
        updated(){
            fixHeaderTable('table-edit', 'header-nav', this.isEditable)
        },
        methods: {
            ...mapActions([
                'updateEditable',
                'updateMessage',
                'updateShowError',
                'updateSingleVisionCategory',
                'updateProgressiveLensCategory',
                'updateVoilaCategory',
                'updateSeriesCategory',
                'updateConventionalCategory',                
            ]),
            turnOnEditable() {
                this.updateEditable(true)
                this.$store.state.survey.editingScreenSaveFunc = this.save;
            },
            turnOffEditable() {
              this.updateEditable(false)
              this.fetch()
              this.loadConfig()
            },
            fetch() {
                let loader = this.$loading.show()

                ConfigurationService.select("RECOMMENDATION_PRODUCT_TYPE", this.surveyId)
                    .then((response) => {
                        var result = response.data.result;
                        this.checkCategoryElife = result.configuration.configValue;
                    })
                    .catch((e) => {
                        console.log(e);
                    });

                LensSliderService.list(this.surveyId)
                    .then(response => {
                        var result = response.data.result
                        console.log(result);
                        this.updateSingleVisionCategory(result.singleVisionCategory);
                        this.updateProgressiveLensCategory(result.progressiveLensCategory);
                        let tempProducts = this.cleanLens(result.voilaCategory.products);
                        this.disabledVoilaProducts = tempProducts[1];
                        result.voilaCategory.products = tempProducts[0];
                        this.updateVoilaCategory(result.voilaCategory);

                        tempProducts = this.cleanLens(result.seriesCategory.products);
                        this.disabledSeriesProducts = tempProducts[1];
                        result.seriesCategory.products = tempProducts[0];
                        this.updateSeriesCategory(result.seriesCategory);
                        this.updateConventionalCategory(result.conventionalLens);
                        loader.hide();
                    })
                    .catch(e => {
                        this.updateMessage(e)
                        this.updateShowError(true)
                        loader.hide()
                    })
            },
            cleanLens(lenses) {
              let disabledLens = [];
              let enabledLens = [];
              lenses.forEach(lens => {
                if (lens.isDisabled === true) {
                  disabledLens.push(lens);
                } else {
                  enabledLens.push(lens);
                }
              });
              return [enabledLens, disabledLens];
            },
            loadConfig() {
                SurveyConfigurationService.list(this.surveyId)
                    .then(response => {
                        var result = response.data.result
                        var configs = result.configurations
                        configs.forEach(config => {
                            if(config.configKey === Constants.LENS_SLIDER_BUTTON_NAME) {
                                this.btnName = config.configValue
                            }
                            if(config.configKey === Constants.LENS_SLIDER_BUTTON_CONTENT) {
                                this.btnContent = config.configValue
                            }
                            if(config.configKey === Constants.LENS_SLIDER_PASSWORD) {
                                this.txtPassword = config.configValue
                            }
                            else if(config.configKey === Constants.LENS_SLIDER_REMOVE_BTN_OPTION) {
                              this.removeBtnOption = (config.configValue == 'true')
                            }
                        })
                    })
                    .catch(e => {
                        this.updateMessage(e)
                        this.updateShowError(true)
                    })
            },
            save(chainFunc) {
              this.updateEditable(false)
              let chain1 = undefined;
              if (chainFunc !== undefined) {
                let completeCount = 0;
                chain1 = () => {
                  completeCount ++;
                  if (completeCount === 3)
                    chainFunc();
                };
              }
              this.saveSurveyConfiguration(chain1);
              this.saveLensSlider(chain1);
              this.saveLensSeriesSlider(chain1);
            },
            saveSurveyConfiguration(chain) {
                /* Save config */
                var configs = []
                configs.push({"configKey" : Constants.LENS_SLIDER_BUTTON_NAME, "configValue": this.btnName, "surveyId" : this.surveyId})
                configs.push({"configKey" : Constants.LENS_SLIDER_BUTTON_CONTENT, "configValue": this.btnContent, "surveyId" : this.surveyId})
                configs.push({"configKey" : Constants.LENS_SLIDER_PASSWORD, "configValue": this.txtPassword, "surveyId" : this.surveyId})
                configs.push({"configKey" : Constants.LENS_SLIDER_REMOVE_BTN_OPTION, "configValue": this.removeBtnOption, "surveyId" : this.surveyId})
                SurveyConfigurationService.save(configs).then(() => {
                  if(chain !== undefined)
                    chain();
                });
            },
            saveLensSlider(chain) {
                /* Save lens slider */
                var products = this.getSingleVisionCategory.products
                    .concat(this.getProgressiveLensCategory.products, this.getVoilaCategory.products, this.disabledVoilaProducts)
                var lensSliders = []
                products.forEach(product => {
                    var lensSlider = product.lensSlider
                    lensSlider.surveyId = this.surveyId
                    lensSlider.categoryId = product.categoryId
                    lensSlider.subCategoryId = product.subCategoryId
                    lensSliders.push(lensSlider)
                })
                LensSliderService.save(lensSliders)
                    .then(() => {
                        if(chain !== undefined)
                          chain();
                        this.fetch()
                    })
                    .catch((e) => {
                        console.log(e);
                    })
            },
            saveLensSeriesSlider(chain) {
                /* Save lens slider */
                var products = this.getSingleVisionCategory.products
                    .concat(this.getProgressiveLensCategory.products, this.getSeriesCategory.products, this.disabledSeriesProducts, this.getConventionalCategory.products)
                var lensSliders = []
                products.forEach(product => {
                    var lensSlider = product.lensSlider
                    lensSlider.surveyId = this.surveyId
                    lensSlider.categoryId = product.categoryId
                    lensSlider.subCategoryId = product.subCategoryId
                    lensSliders.push(lensSlider)
                })
                LensSliderService.save(lensSliders)
                    .then(() => {
                        if(chain !== undefined)
                          chain();
                        this.fetch()
                    })
                    .catch((e) => {
                        console.log(e);
                    })
            },
        },
    }
</script>

<style scoped>
  .content {
    padding: 0px;
  }
  
  .header-desc {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    margin-top: 5px;
    padding-right: 10%;
  }
  
  .type-desc {
    font-size: 13px;
    font-weight: bold;
    color: #006db6;
    padding-right: 10%;
  }
  
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: table;
    transition: opacity .3s ease;
  }
  
  .modal-mask-confirm {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 71%, 0.5);
    display: table;
    transition: opacity .3s ease;
  }
  
  .custom-modal-dialog {
    margin: 0 auto !important;
    max-width: 100% !important;
    display: flex;
    justify-content: center;
  }
  
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  
  .custom-dialog {
    width: 1034px;
    height: 602px;
    background: white;
  }
  
  .custom-dialog-confirm {
    width: 467px;
    height: 283px;
    background: #4B4B4B;
  }
  
  .modal-custom-header {
    background: #E5F4FF;
    margin: 0px 0px 15px 0px;
    padding: 10px;
  }
  
  .modal-custom-body {
    background: white;
    margin: 0px;
    padding: 0px 25px;
  }
  
  .modal-custom-body-row {
    background: #f6f6f6;
    margin: 0px 25px;
    padding: 0px;
  }
  
  .modal-custom-product {
    background: white;
    margin: 0px 25px;
    min-height: 150px;
  }
  
  .body-text-modal{
    text-align: left;
    font-size: 15px;
    line-height: 23px;
    color: black;
  }
  
  .body-text-modal-confirm {
    width: 294px;
    height: 56px;
    margin: 0 auto;
    text-align: center;
    padding: 60px 0px;
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 25px;
    color: white;
  }
  
  .btn-modal {
    float: right;
  }
  .dis_checkbox {
    pointer-events: none;
  }
  .btn-modal-confirm {
    display: flex;
    justify-content: center;
  }
  td {
    word-break: break-word;
  }

  .main {
  position: relative;
  display: inline-block;
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
  background-color: #fff;
  width: 100%;
}
.main span {
  color: #768192;
  position: absolute;
  bottom: .375rem;
  right: .75rem;
  font-size: 10px;
}

.main textarea {
  border: none!important;
  margin-bottom: 20px;
  width: 100%;
}

.main textarea:focus, .main textarea:active {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

</style>
