import { http } from "../plugin/http2-common";

class LensSliderService {
  list(surveyId) {
    return http.get("/lensSlider/list/" + surveyId);
  }
  save(data) {
    return http.put("/lensSlider/update", data);
  }
}

export default new LensSliderService();
