<template>
  <div>
    <b-img
      v-if="product.lensSlider.imageWithWireframe"
      :id="`popover-image-SingleVisionImageWithWireframe-${index}`"
      class="img-wireframe"
      :src="imagePath + product.lensSlider.imageWithWireframe"
      fluid
      :alt="product.name"
    />
    <ImagePopover
      :product-type="`SingleVisionImageWithWireframe`"
      :current-row-index="index"
      :current-name="product.name"
      :current-image="imagePath + product.lensSlider.imageWithWireframe"
    />
    <!-- <img
      v-if="isEditable"
      class="img-replace"
      src="@/assets/icons/replace.png"
      fluid
      alt="Replace"
      @click="replaceSVImageWithWireframe()"
    > -->
    <img
      v-if="isEditable && product.lensSlider.imageWithWireframe"
      class="img-replace"
      src="@/assets/icons/replace.png"
      fluid
      alt="Replace"
      @click="replaceSVImageWithWireframe()"
    >
    <img
      v-if="isEditable && !product.lensSlider.imageWithWireframe"
      class="img-replace"
      src="@/assets/icons/upload.png"
      fluid
      alt="Upload"
      @click="replaceSVImageWithWireframe()"
    >
    <input
      ref="svImageWithWireframe"
      class="choose-file"
      type="file"
      @change="chooseSVImageWithWireframe($event)"
    >
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Constants from '../../../common/Constants';
import UploadFileService from "../../../service/UploadFileService";
import ImagePopover from "../../common/ImagePopover.vue";

export default {
  components: {
    ImagePopover
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    imagePath: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
    }
  },
  computed:{
    ...mapGetters(['isEditable']),
  },
  methods: {
    ...mapActions([
      'updateMessage',
      'updateShowError',
      'updateProgress',
      'updateSingleVisionCategoryImageWithWireframe',
    ]),
    replaceSVImageWithWireframe() {
      const elem = this.$refs.svImageWithWireframe
      elem.click()
    },
    chooseSVImageWithWireframe(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length)
        return
      this.createSVImageWithWireframe(files[0], e.target)
    },
    createSVImageWithWireframe(file, input) {
      let loader = this.$loading.show();
      UploadFileService.checkImgBeforeUpload(file)
      .then(() => {
        UploadFileService.uploadLargeFileWithFileNamePrefix(file, "slider_Voila_with_wire_frame_image", "SLIDER", event => {
          this.updateProgress(Math.round((100 * event.loaded) / event.total))
        })
        .then(response => {
          loader.hide()
          var imageName = response.data.name;
          this.updateSingleVisionCategoryImageWithWireframe({index : this.index, image : imageName})
        })
        .catch((error) => {
          loader.hide()
          this.updateMessage(error);
          this.updateShowError(true)
          this.updateProgress(0)
          input.value = null;
        });
      }).catch(e => {
        console.log(e);
        loader.hide();
        if (e === Constants.LENS_SLIDER_TYPE_ERROR) {
          this.updateMessage('Only .png, .jpg, .jpeg are allowed, please check your image again');
        }else if(e === Constants.LENS_SLIDER_MAX_SIZE_ERROR) {
          this.updateMessage('the maximum size is 1MB, please check your image again');
        }else if(e === Constants.LENS_SLIDER_RESOLUTION_ERROR) {
          this.updateMessage('The maximum dimension is 1641x976px, please check your image again');
        }else {
          this.updateMessage('Error when upload image');
        }
        this.updateShowError(true)
        this.updateProgress(0)
        input.value = null;
      })
    },
  },
}
</script>

<style>
.img-wireframe {
  height: 68px;
  width: 68px;
}

.img-replace {
  height: 18px;
  width: 18px;
}

.choose-file {
  display: none;
}
</style>